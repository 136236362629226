<template>
  <base-section
    id="about-our-product"
    :style="{'padding': padding}"
  >
    <base-section-heading
      color="grey lighten-2"
      :icon="icon"
      :title="title"
    >
      <div v-html="description" />
    </base-section-heading>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionHeadedDescription',
    props: {
      title: {
        type: String,
        default: 'title',
      },
      description: {
        type: String,
      },
      icon: {
        type: String,
        default: 'mdi-checkbox-marked-circle-outline',
      },
      padding: {
        type: String,
        default: '48px 0px',
      },
    },
  }
</script>
